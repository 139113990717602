import _crypto from "crypto";
import _jsbn from "jsbn";
import _ec from "./lib/ec.js";
import _saferBuffer from "safer-buffer";
import _sec from "./lib/sec.js";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var crypto = _crypto;
var BigInteger = _jsbn.BigInteger;
var ECPointFp = _ec.ECPointFp;
var Buffer = _saferBuffer.Buffer;
exports.ECCurves = _sec; // zero prepad

function unstupid(hex, len) {
  return hex.length >= len ? hex : unstupid("0" + hex, len);
}

exports.ECKey = function (curve, key, isPublic) {
  var priv;
  var c = curve();
  var n = c.getN();
  var bytes = Math.floor(n.bitLength() / 8);

  if (key) {
    if (isPublic) {
      var curve = c.getCurve(); //      var x = key.slice(1,bytes+1); // skip the 04 for uncompressed format
      //      var y = key.slice(bytes+1);
      //      this.P = new ECPointFp(curve,
      //        curve.fromBigInteger(new BigInteger(x.toString("hex"), 16)),
      //        curve.fromBigInteger(new BigInteger(y.toString("hex"), 16)));      

      (this || _global).P = curve.decodePointHex(key.toString("hex"));
    } else {
      if (key.length != bytes) return false;
      priv = new BigInteger(key.toString("hex"), 16);
    }
  } else {
    var n1 = n.subtract(BigInteger.ONE);
    var r = new BigInteger(crypto.randomBytes(n.bitLength()));
    priv = r.mod(n1).add(BigInteger.ONE);
    (this || _global).P = c.getG().multiply(priv);
  }

  if ((this || _global).P) {
    //  var pubhex = unstupid(this.P.getX().toBigInteger().toString(16),bytes*2)+unstupid(this.P.getY().toBigInteger().toString(16),bytes*2);
    //  this.PublicKey = Buffer.from("04"+pubhex,"hex");
    (this || _global).PublicKey = Buffer.from(c.getCurve().encodeCompressedPointHex((this || _global).P), "hex");
  }

  if (priv) {
    (this || _global).PrivateKey = Buffer.from(unstupid(priv.toString(16), bytes * 2), "hex");

    (this || _global).deriveSharedSecret = function (key) {
      if (!key || !key.P) return false;
      var S = key.P.multiply(priv);
      return Buffer.from(unstupid(S.getX().toBigInteger().toString(16), bytes * 2), "hex");
    };
  }
};

export default exports;
export const ECCurves = exports.ECCurves,
      ECKey = exports.ECKey;